@import (reference) '~theme/globals/site.variables';
@import (reference) '../../brandPalette.less';

g.axes {
  .visx-axis {
    &.secondary {
      & > line {
        opacity: 0.1;
      }
    }
  }
  .visx-rows, .visx-columns {
    line {
      stroke: @grey;
      opacity: 0.2;
      stroke-dasharray: 2px;
      pointer-events: none;
    }
  }
}
